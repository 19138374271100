import { mutate } from 'swr'

import { QuarterType } from '~/types/types'
import { authorizedFetch } from '../../../auth/fetch'
import { config } from '../../../config'

const addQuarter = (quarters: QuarterType[], q: number, y: number) => {
  const newQuarts: QuarterType[] = []

  // CREATING QUARTER OBJECT WITH PREV AND NEXT VALUES
  const getNextQuartObj = (quart: QuarterType) => {
    const nextQ = quart.q === 4 ? 1 : quart.q + 1
    const nextY = quart.q === 4 ? quart.y + 1 : quart.y
    return {
      q: nextQ,
      y: nextY
    }
  }

  const handlePost = () => {
    newQuarts.forEach(async quart => {
      mutate(config.quarters, [...quarters, quart], false)
      const res = await authorizedFetch(config.quarters, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(quart)
      })
      if (!res.ok) {
        throw new Error('Bad response: ' + res.status)
      }
    })
    mutate(config.quarters)
  }

  // ADDING NEW QUARTER FUNCTION
  const addNewQuarters = async () => {
    const quarts = quarters.length ? [...quarters] : []
    const dist = (y - 2018) * 4 + (q - 1)

    for (let i = 0; i < dist + 1; i++) {
      const quart: QuarterType = getNextQuartObj(quarts[i])
      const exists = quarts.find(x => x.q === quart.q && x.y === quart.y)
      // If not existing, add it
      if (!exists) {
        quarts.push(quart)
        newQuarts.push(quart)
      }
    }
    handlePost()
  }

  addNewQuarters()
}

export default addQuarter
