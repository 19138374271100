import { Button, Grid, Inline, Modal } from '@intility/bifrost-react'
import Select from '@intility/bifrost-react-select'
import React, { useEffect, useState } from 'react'
import { MultiValue } from 'react-select'
import { mutate } from 'swr'

import { CardType, SelectType, TagType } from '~/types/types'
import { authorizedFetch } from '../../../auth/fetch'
import { config } from '../../../config'
import '../DeleteTagModal/deleteTagModal.css'

type DeleteTagModalProps = {
  cards: CardType[]
  openDeleteTag: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  setOpenDeleteTag: React.Dispatch<React.SetStateAction<boolean>>
  tag: TagType
  tags: TagType[]
}

function DeleteTagModal({
  cards,
  openDeleteTag,
  setEditMode,
  setIsFetching,
  setOpenDeleteTag,
  tag,
  tags
}: DeleteTagModalProps) {
  const [submitted, setSubmitted] = useState(false)
  const [cardsToBeUpdated, setCardsToBeUpdated] = useState<CardType[]>([])

  // Creates array of cards using the selected tag
  useEffect(() => {
    const filtered = cards.filter(c => c.options.tags?.includes(tag.tag))
    setCardsToBeUpdated(filtered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // DELETING TAG FROM DB
  const handleDeleteTag = async () => {
    setOpenDeleteTag(false)
    setIsFetching(true)
    const newTags = tags.filter(t => t.id !== tag.id)
    mutate(config.tags, newTags, false)
    setIsFetching(false)

    try {
      const res = await authorizedFetch(config.tags + `/${tag.id}`, {
        method: 'DELETE'
      })
      if (!res.ok) {
        throw new Error('Could not delete tag')
      }
    } catch (e) {
      console.log(e)
    } finally {
      mutate(config.tags)
    }
  }

  // CLOSE MODAL
  const handleModalClose = () => {
    setCardsToBeUpdated([])
    setSubmitted(false)
    setOpenDeleteTag(false)
  }

  const onChangeTag = (
    items: MultiValue<{
      value: string
      label: string
    }>,
    card: CardType
  ) => {
    const tags = [tag.tag]
    items.forEach(item => {
      tags.push(item.value)
    })

    const index = cardsToBeUpdated.findIndex(c => c === card)
    const newArr = [...cardsToBeUpdated]
    newArr[index].options.tags = tags.toString()
    setCardsToBeUpdated(newArr)
  }

  // IF CARD HAS TAG, UPDATE CARD WITH NEW TAG BEFORE DELETING
  const updateCard = async (card: CardType) => {
    setIsFetching(true)
    const newTags = card.options.tags?.split(',').filter(t => t !== tag.tag)
    card.options.tags = newTags?.toString()
    const newCards = cards.map(c => (c === card ? card : c))
    mutate(config.cards, newCards, false)
    try {
      const res = await authorizedFetch(config.cards + `/${card.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(card)
      })
      if (!res.ok) {
        throw new Error('Could not update card: ' + card.options.title)
      }
    } catch (e) {
      console.log(e)
    } finally {
      mutate(config.cards)
      setIsFetching(false)
    }
  }

  // IF CARD HAS TAG, UPDATE CARD WITH NEW TAG BEFORE DELETING
  const handleDelete = () => {
    if (!cardsToBeUpdated.length) {
      handleDeleteTag()
      setEditMode(false)
      handleModalClose()
    }

    if (cardsToBeUpdated.length) {
      if (
        cardsToBeUpdated.some(c => c.options.tags?.length === tag.tag.length)
      ) {
        setSubmitted(true)
        return
      } else {
        handleModalClose()
        cardsToBeUpdated.forEach(c => {
          updateCard(c)
        })
        handleDeleteTag()
        setEditMode(false)
      }
    }
  }

  // SETTING TAG OPTIONS FOR SELECT COMPONENT
  const tagOptions: SelectType[] = []
  const newOptions = tags.filter(x => x.tag !== tag.tag)
  newOptions.forEach(x => tagOptions.push({ value: x.tag, label: x.tag }))
  tagOptions.sort()

  return (
    <Modal
      isOpen={openDeleteTag}
      onRequestClose={() => handleModalClose()}
      header='Delete tag'
    >
      <Grid>
        <div>
          You are about to delete the tag <b>{tag.tag}</b>. Are you sure?
        </div>

        {cardsToBeUpdated.length > 0 && (
          <>
            <div>
              There are {cardsToBeUpdated.length} projects with this tag. You
              have to assign them a new tag before deleting it.
            </div>

            <Grid className='deleteTagSelectContainer bf-scrollbar-small'>
              {cardsToBeUpdated.map(c => (
                <Select
                  key={c.id}
                  name='Tag'
                  label={c.options.title}
                  options={tagOptions}
                  value={c.options.tags
                    ?.split(',')
                    .filter(t => t !== tag.tag)
                    .map(t => ({ value: t, label: t }))}
                  onChange={e => onChangeTag(e, c)}
                  placeholder=' - Select tags - '
                  {...(submitted &&
                    c.options.tags?.includes(tag.tag) &&
                    c.options.tags.length === tag.tag.length && {
                      state: 'alert',
                      feedback: 'Project must have at least one tag'
                    })}
                  isMulti
                />
              ))}
            </Grid>
          </>
        )}

        <Inline style={{ marginTop: '12px' }}>
          <Button onClick={() => setOpenDeleteTag(false)}>Cancel</Button>
          <Inline.Separator />
          <Button onClick={() => handleDelete()} variant='filled' state='alert'>
            Delete
          </Button>
        </Inline>
      </Grid>
    </Modal>
  )
}

export default DeleteTagModal
