import { Button, Grid, Theme, useApplyTheme } from '@intility/bifrost-react'
import useLocalStorageState from 'use-local-storage-state'

import RoadmapLogo from '../assets/Roadmap_192px.png'
import instance from './instance'
import './loginPage.css'

const LoginPage = () => {
  const [theme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'dark'
  })

  // keep document theme in sync with state
  useApplyTheme(theme)

  return (
    <div className='loginPage bf-content'>
      <div className='loginBox'>
        <img alt='' src={RoadmapLogo} className='roadmapLogo' />
        <Grid gap={8}>
          <Grid gap={4}>
            <span className='bfc-base-2'>Welcome to</span>
            <h2 className='loginBoxTitle'>Intility Roadmap</h2>
          </Grid>
          <span className='bfc-base-2'>
            Our Roadmap provides estimated release dates and descriptions for
            upcoming features and services.
          </span>
        </Grid>

        <div className='loginBoxFooter'>
          <Button
            className='loginButton'
            onClick={() => instance.loginRedirect()}
            variant='filled'
            pill
          >
            Sign in
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
