import { Checkbox, Label } from '@intility/bifrost-react'
import React from 'react'

import { CardType, FilterType } from '~/types/types'
import './filterMenu.css'

type FilterPricingModelProps = {
  cards: CardType[]
  filters: FilterType
  pricings: string[]
  setShowAll: (title: string) => void
  togglePricings: (
    pricing: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

// FILTER PRICING MENU COMPONENT
const FilterPricingModel = ({
  cards,
  filters,
  pricings,
  setShowAll,
  togglePricings
}: FilterPricingModelProps) => {
  const title = 'Pricing model'

  return (
    <div>
      <Label className='filterTitle'>{title}</Label>

      <div className='filterMenuContent'>
        <Checkbox
          label={
            'Show all (' +
            (!filters.count ? cards.length : filters?.countPricing?.length) +
            ')'
          }
          checked={filters.pricing.length === pricings.length}
          onChange={() => setShowAll(title)}
        />
        <Checkbox
          label={
            pricings[0] +
            ' (' +
            (!filters.count
              ? cards.filter(c => c.options.includedService).length
              : filters?.countPricing?.filter(c => c.options.includedService)
                  ?.length) +
            ')'
          }
          checked={filters.pricing.indexOf(pricings[0]) > -1}
          onChange={e => togglePricings(pricings[0], e)}
          disabled={
            !filters.count
              ? !cards.filter(c => c.options.includedService).length
              : !filters?.countPricing?.filter(c => c.options.includedService)
                  ?.length
          }
        />
        <Checkbox
          label={
            pricings[1] +
            ' (' +
            (!filters.count
              ? cards.filter(c => !c.options.includedService).length
              : filters?.countPricing?.filter(c => !c.options.includedService)
                  ?.length) +
            ')'
          }
          checked={filters.pricing.indexOf(pricings[1]) > -1}
          onChange={e => togglePricings(pricings[1], e)}
          disabled={
            !filters.count
              ? !cards.filter(c => !c.options.includedService).length
              : !filters?.countPricing?.filter(c => !c.options.includedService)
                  ?.length
          }
        />
      </div>
    </div>
  )
}

export default FilterPricingModel
