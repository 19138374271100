import { AccountInfo } from '@azure/msal-browser'
import {
  Button,
  Checkbox,
  Grid,
  Inline,
  Input,
  Label,
  Modal,
  TextArea
} from '@intility/bifrost-react'
import DatePicker from '@intility/bifrost-react-datepicker'
import Select from '@intility/bifrost-react-select'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import useDisplayMode from '~/hooks/useDisplayMode'
import { CardOptionsType, CardType, QuarterType, TagType } from '~/types/types'
import { resetWindowScroll } from '../../../utils/resetWindowScroll'
import './AddCardModal.css'
import addCard from './addCard'
import addQuarter from './addQuarter'

type AddCardModalProps = {
  cards: CardType[]
  quarters: QuarterType[]
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  tags: TagType[]
  user: AccountInfo | null
}

function AddCardModal({
  cards,
  quarters,
  setNewCard,
  open,
  setOpen,
  tags,
  user
}: AddCardModalProps) {
  const [options, setOptions] = useState<CardOptionsType>({
    created: new Date(),
    createdBy: user?.username,
    includedService: false,
    lastEditedBy: user?.username,
    override: false
  } as CardOptionsType)

  const { displayMode } = useDisplayMode()
  const [validatedEmail, setValidatedEmail] = useState(true)
  const [validatedDate, setValidatedDate] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const missingValue = 'Missing value'

  // CREATING TAG OPTIONS FOR SELECT COMPONENT
  const tagOptions = useMemo(() => {
    if (tags) {
      return tags
        .flat()
        .map(x => ({
          label: x.tag,
          value: x.tag
        }))
        .sort()
    }
  }, [tags])

  // VALIDATING EMAIL
  function validateEmail(mail: string) {
    const mailFormat = /^\w+(.\w+)*@\w+(.\w+)*(\.\w{2,3})+$/
    setValidatedEmail(mail.match(mailFormat) !== null)
  }

  // VALIDATING DATE
  useEffect(() => {
    if (!options.startDate || !options.endDate) return
    setValidatedDate(options.endDate >= options.startDate)
  }, [options.startDate, options.endDate])

  // HANDLE INPUT BASED ON FIELD CHANGED
  const handleInput = (type: string, value: string) => {
    switch (type) {
      case 'Title':
        setOptions({ ...options, title: value })
        break
      case 'Email':
        setOptions({ ...options, email: value })
        break
      case 'ShortDesc':
        setOptions({ ...options, shortDesc: value })
        break
      case 'Desc':
        setOptions({ ...options, desc: value })
        break
      default:
    }
  }

  // ON MODAL CLOSE
  const handleModalClose = () => {
    resetWindowScroll()
    setSubmitted(false)
    setOpen(false)
  }

  // ON CANCEL, RESET ALL INPUTS
  const handleModalCancel = () => {
    handleModalClose()
    setValidatedEmail(true)
    setOptions({
      created: new Date(),
      createdBy: user?.username,
      includedService: false,
      lastEditedBy: user?.username,
      override: false,
      tags: undefined
    } as CardOptionsType)
  }

  const isValidSubmit = () => {
    if (
      options.title &&
      options.startDate &&
      options.endDate &&
      options.email &&
      options.shortDesc &&
      options.tags &&
      validatedEmail &&
      validatedDate
    ) {
      return true
    }
    return false
  }

  // SUBMITTING IF ALL REQUIRED FIELDS HAS VALUE
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    setSubmitted(true)
    if (!isValidSubmit()) {
      return
    }
    // Quarter and year
    const q = Math.ceil((options.endDate.getMonth() + 1) * 0.33)
    const y = options.endDate.getFullYear()
    // If quarter does not exist, add quarters
    const quarterExist = quarters.find(x => x.q === q && x.y === y)
    if (!quarterExist) {
      addQuarter(quarters, q, y)
    }
    // Add card
    addCard(cards, options, setNewCard)
    handleModalCancel()
  }

  return (
    <Modal
      header='Add project'
      isOpen={open}
      onRequestClose={() => handleModalClose()}
      noCloseOnOverlayClick
      className={classNames('modal', 'bf-content', displayMode)}
    >
      <Grid gap={16} className='contentGrid'>
        <Input
          className='projectTitle'
          label='Title'
          placeholder='Project title'
          value={options.title || ''}
          {...(submitted &&
            !options.title && {
              state: 'alert',
              feedback: missingValue
            })}
          onChange={e => setOptions({ ...options, title: e.target.value })}
        />

        <div className='overrideStatusContainer'>
          <Label>Override status</Label>
          <Checkbox
            className='checkboxStatus'
            label='Project launched'
            checked={options.override}
            onChange={() =>
              setOptions({ ...options, override: !options.override })
            }
          />
        </div>

        <div className='pricingModelContainer'>
          <Label>Pricing model</Label>
          <Checkbox
            className='checkboxStatus'
            label='Included'
            checked={options.includedService}
            onChange={() =>
              setOptions({
                ...options,
                includedService: !options.includedService
              })
            }
          />
        </div>

        <Input
          className='contactInfo'
          label='Contact e-mail'
          value={options.email || ''}
          {...(!validatedEmail &&
            !submitted &&
            options.email && {
              state: 'warning',
              feedback: 'Invalid email address'
            })}
          {...(!validatedEmail &&
            submitted &&
            options.email && {
              state: 'alert',
              feedback: 'Invalid email address'
            })}
          {...(submitted &&
            !options.email && { state: 'alert', feedback: missingValue })}
          onChange={e => setOptions({ ...options, email: e.target.value })}
          onBlur={e => validateEmail(e.target.value)}
          placeholder='Ex. department@intility.no'
          autoComplete='off'
        />

        <Select
          name='Tag'
          label='Tags'
          className='selectTags'
          placeholder='Select tags'
          value={
            options.tags && options.tags.length > 0
              ? options.tags?.split(',').map(t => ({ label: t, value: t }))
              : []
          }
          options={tagOptions}
          isMulti
          onChange={e =>
            setOptions(old => ({
              ...old,
              tags: e.length > 0 ? e.map(t => t.value).join(',') : ''
            }))
          }
          {...(submitted &&
            !options.tags && {
              state: 'alert',
              feedback: missingValue
            })}
        />

        <DatePicker
          className='startDate'
          label='Start date'
          selected={options.startDate}
          onChange={d => {
            if (!d) return
            const formatted = new Date(d.setHours(12))
            setOptions({ ...options, startDate: formatted })
          }}
          {...(submitted &&
            !options.startDate?.toString() && {
              state: 'alert',
              feedback: missingValue
            })}
          {...(!submitted &&
            !validatedDate && {
              state: 'warning',
              feedback: 'Must be before end date'
            })}
          {...(submitted &&
            !validatedDate && {
              state: 'alert',
              feedback: 'Must be before end date'
            })}
        />

        <DatePicker
          className='endDate'
          label='End date'
          selected={options.endDate}
          onChange={d => {
            if (!d) return
            const formatted = new Date(d.setHours(12))
            setOptions({ ...options, endDate: formatted })
          }}
          {...(submitted &&
            !options.endDate?.toString() && {
              state: 'alert',
              feedback: missingValue
            })}
          {...(!submitted &&
            !validatedDate && {
              state: 'warning',
              feedback: 'Must be after start date'
            })}
          {...(submitted &&
            !validatedDate && {
              state: 'alert',
              feedback: 'Must be after start date'
            })}
        />

        <Input
          name='ShortDesc'
          id='shortDescInput'
          className='shortDescInput'
          label='Brief description'
          description='Will be shown on project cards in the overview'
          value={options.shortDesc || ''}
          {...(submitted &&
            !options.shortDesc && {
              state: 'alert',
              feedback: missingValue
            })}
          onChange={e => handleInput('ShortDesc', e.target.value)}
          autoComplete='off'
        />

        <TextArea
          name='Desc'
          className='descInput'
          label='Additional description'
          description='Will only be shown after opening a project card'
          value={options.desc || ''}
          optional
          onChange={e => handleInput('Desc', e.target.value)}
        />

        <Inline className='modalFooter'>
          <Button onClick={() => handleModalCancel()}>Cancel</Button>
          <Inline.Separator />
          <Button variant='filled' onClick={handleSubmit}>
            Save
          </Button>
        </Inline>
      </Grid>
    </Modal>
  )
}

export default AddCardModal
