import { Checkbox, Label } from '@intility/bifrost-react'

import { CardType, FilterType } from '~/types/types'
import './filterMenu.css'

type FilterStatusMenuProps = {
  cards: CardType[]
  filters: FilterType
  setShowAll: (title: string) => void
  statuses: string[]
  toggleStatus: (status: string, e: React.ChangeEvent<HTMLInputElement>) => void
}

// FILTER STATUS MENU COMPONENT
const FilterStatusMenu = ({
  cards,
  filters,
  setShowAll,
  statuses,
  toggleStatus
}: FilterStatusMenuProps) => {
  const title = 'Status'

  return (
    <div>
      <Label className='filterTitle'>{title}</Label>

      <div className='filterMenuContent'>
        <Checkbox
          label={
            'Show all (' +
            (!filters.count ? cards.length : filters?.countStatus?.length) +
            ')'
          }
          checked={filters.statuses.length === statuses.length}
          onChange={() => setShowAll(title)}
        />
        {statuses.map(status => (
          <Checkbox
            label={
              status +
              ' (' +
              (!filters.count
                ? cards.filter(c => c.options.status === status).length
                : filters?.countStatus?.filter(c => c.options.status === status)
                    .length) +
              ')'
            }
            key={status}
            checked={filters.statuses.indexOf(status) > -1}
            onChange={e => toggleStatus(status, e)}
            disabled={
              !filters.count
                ? !cards.filter(c => c.options.status === status).length
                : !filters?.countStatus?.filter(
                    c => c.options.status === status
                  ).length
            }
          />
        ))}
      </div>
    </div>
  )
}

export default FilterStatusMenu
