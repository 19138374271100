import { CardType, FilterType } from '~/types/types'

const handleCountUpdate = (
  cards: CardType[],
  filters: FilterType,
  pricings: string[],
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>
) => {
  // ARRAY CONTAINING CARDS FILTERED ON SEARCH AND/OR YEAR
  // This is so tags and statuses contains counters when tag or status is selected
  setFilters({
    ...filters,
    count: cards,

    // YEAR
    countYear: cards.filter(
      x =>
        // Apply filter for tags if any selected
        (!filters.tags.length ||
          (x.options.tags &&
            filters.tags.some(y => x.options.tags?.includes(y)))) &&
        // Apply filter for statuses if any selected
        (!filters.statuses.length ||
          filters.statuses.includes(x.options.status)) &&
        // Apply filter for pricing if any selected or both selected
        (!filters.pricing.length ||
          filters.pricing.length === 2 ||
          (filters.pricing.includes(pricings[0])
            ? x.options.includedService
            : !x.options.includedService)) &&
        // Apply filter by year if any selected
        x.options.title.toLowerCase().includes(filters.search.toLowerCase())
    ),

    // TAGS
    countTags: cards.filter(
      x =>
        (!filters.statuses.length ||
          filters.statuses.includes(x.options.status)) &&
        (!filters.year ||
          (filters.year.value === 'Earlier'
            ? x.year < 2018
            : x.year === filters.year.value)) &&
        (!filters.pricing.length ||
          filters.pricing.length === 2 ||
          (filters.pricing.includes(pricings[0])
            ? x.options.includedService
            : !x.options.includedService)) &&
        x.options.title.toLowerCase().includes(filters.search.toLowerCase())
    ),

    // STATUS
    countStatus: cards.filter(
      x =>
        (!filters.tags.length ||
          (x.options.tags &&
            filters.tags.some(y => x.options.tags?.includes(y)))) &&
        (!filters.year ||
          (filters.year.value === 'Earlier'
            ? x.year < 2018
            : x.year === filters.year.value)) &&
        (!filters.pricing.length ||
          filters.pricing.length === 2 ||
          (filters.pricing.includes(pricings[0])
            ? x.options.includedService
            : !x.options.includedService)) &&
        x.options.title.toLowerCase().includes(filters.search.toLowerCase())
    ),

    // PRICING
    countPricing: cards.filter(
      x =>
        (!filters.tags.length ||
          (x.options.tags &&
            filters.tags.some(y => x.options.tags?.includes(y)))) &&
        (!filters.year ||
          (filters.year.value === 'Earlier'
            ? x.year < 2018
            : x.year === filters.year.value)) &&
        (!filters.statuses.length ||
          filters.statuses.includes(x.options.status)) &&
        x.options.title.toLowerCase().includes(filters.search.toLowerCase())
    )
  })
}

export default handleCountUpdate
