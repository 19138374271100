import Select from '@intility/bifrost-react-select'
import React from 'react'

import { CardType, FilterType } from '~/types/types'
import '../filterMenu.css'

type FilterYearMenuProps = {
  cards: CardType[]
  filters: FilterType
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>
}

type YearOptionType = {
  value: string | number
  label: string
  isDisabled?: boolean
}

const FilterYearMenu = ({
  cards,
  filters,
  setFilters
}: FilterYearMenuProps) => {
  // YEAR OPTIONS
  const yearOptions: YearOptionType[] = []
  const years: number[] = [
    ...new Set(cards.filter(x => x.year >= 2018).map(c => c.year))
  ]

  years.sort()
  years.reverse()
  years.forEach(y =>
    yearOptions.push({
      value: y,
      label:
        y.toString() +
        ' (' +
        (filters.countYear
          ? filters.countYear.filter(c => c.year === y).length
          : cards.filter(c => c.year === y).length) +
        ')',
      isDisabled:
        filters.countYear &&
        filters.countYear.filter(c => c.year === y).length === 0
    })
  )

  yearOptions.push({
    value: 'Earlier',
    label:
      'Earlier (' +
      (filters.countYear
        ? filters.countYear.filter(c => c.year < 2018).length
        : cards.filter(c => c.year < 2018).length) +
      ')',
    isDisabled:
      filters.countYear &&
      filters.countYear.filter(c => c.year < 2018).length === 0
  })

  return (
    <div className='rm-select-year'>
      <Select
        label='Filter by year'
        options={yearOptions}
        placeholder='Select year'
        value={
          filters.year && filters.year.value === 'Earlier'
            ? {
                value: filters.year.value,
                label:
                  filters.year.value +
                  ' (' +
                  (filters.countYear
                    ? filters.countYear.filter(c => c.year < 2018).length
                    : cards.filter(c => c.year < 2018).length) +
                  ')'
              }
            : filters.year &&
              filters.year.value !== 'Earlier' && {
                value: filters.year.value,
                label:
                  filters.year.value.toString() +
                  ' (' +
                  (filters.countYear
                    ? filters.countYear.filter(
                        c => c.year === filters?.year?.value
                      ).length
                    : cards.filter(c => c.year === filters?.year?.value)
                        .length) +
                  ')'
              }
        }
        onChange={item => {
          setFilters({ ...filters, year: item ? item : null })
        }}
        isClearable
      />
    </div>
  )
}

export default FilterYearMenu
