// Dragging window with mouse
const dragElement = (
  elmnt: HTMLDivElement,
  setDragging: React.Dispatch<React.SetStateAction<boolean>>,
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  columnWidth: number
) => {
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0

  elmnt.onmousedown = dragMouseDown

  function dragMouseDown(e: MouseEvent) {
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag(e: MouseEvent) {
    setDragging(true)
    e.preventDefault()
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY
    // set the element's new position:
    elmnt.scrollLeft = elmnt.scrollLeft + pos1
    elmnt.scrollTop = elmnt.scrollTop + pos2
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    setActiveIndex(Math.floor(elmnt.scrollLeft / columnWidth))
    document.onmouseup = null
    document.onmousemove = null
    setDragging(false)
  }
}

export default dragElement
