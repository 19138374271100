import { Button, Grid, Inline, Modal } from '@intility/bifrost-react'
import React from 'react'
import { mutate } from 'swr'

import { CardType } from '~/types/types'
import { authorizedFetch } from '../../../auth/fetch'
import { config } from '../../../config'

type DeleteCardModalProps = {
  card: CardType
  cards: CardType[]
  openConfirm: boolean
  setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteCardModal = ({
  card,
  cards,
  openConfirm,
  setOpenConfirm
}: DeleteCardModalProps) => {
  // Deleting a card
  const handleDelete = async () => {
    setOpenConfirm(false)

    const newCards = cards.filter(c => c.id !== card.id)
    mutate(config.cards, newCards, false)

    try {
      const res = await authorizedFetch(config.cards + `/${card.id}`, {
        method: 'DELETE'
      })
      if (!res.ok) {
        throw new Error('Could not delete card')
      }
    } catch (e) {
      console.log(e)
    } finally {
      mutate(config.cards)
    }
  }

  return (
    <Modal
      isOpen={openConfirm}
      onRequestClose={() => setOpenConfirm(false)}
      header='Delete project'
    >
      <Grid gap={24} style={{ maxWidth: '420px' }}>
        <span>
          Are you sure you want to delete {<i>{card.options.title}</i>}? You
          will not be able to restore the project after deleting it.
        </span>

        <Inline>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Inline.Separator />
          <Button onClick={() => handleDelete()} variant='filled' state='alert'>
            Delete
          </Button>
        </Inline>
      </Grid>
    </Modal>
  )
}

export default DeleteCardModal
