import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@intility/bifrost-react'
import classNames from 'classnames'
import React from 'react'

import { CardType, QuarterType } from '~/types/types'
import styles from './timelinebar.module.css'

type TimelineBarProps = {
  cardsLength: number
  filteredCards: CardType[]
  getQuarter: (date: Date) => number
  handleNext: () => void
  handlePrev: () => void
  leftBtnEnabled: boolean
  quarters: QuarterType[]
  quarterTitleRef: React.RefObject<HTMLDivElement>
  rightBtnEnabled: boolean
}

const TimelineBar = ({
  cardsLength,
  filteredCards,
  getQuarter,
  handleNext,
  handlePrev,
  leftBtnEnabled,
  quarters,
  quarterTitleRef,
  rightBtnEnabled
}: TimelineBarProps) => {
  return (
    <div className={styles.timelinebar}>
      <button
        onClick={() => handlePrev()}
        className={styles.leftMoveBtn}
        disabled={!leftBtnEnabled}
      >
        <div className={styles.iconContainer}>
          <Icon icon={faChevronLeft} />
        </div>
      </button>

      <div className={styles.quarterTitleWrapper} ref={quarterTitleRef}>
        <div className={styles.quarterTitleContainer}>
          {filteredCards &&
            filteredCards.filter(c => c.year < 2018).length > 0 && (
              <span className={styles.quarterTitle}>Released earlier</span>
            )}

          {quarters
            .filter(q => q.y >= 2018)
            .map(quart => {
              const quarterCards = filteredCards.filter(
                x => x.quart === quart.q && x.year === quart.y
              )

              if (!quarterCards.length && filteredCards.length !== cardsLength)
                return null

              return (
                <span
                  key={quart.id}
                  className={classNames(styles.quarterTitle, {
                    [styles.activeQuarter]:
                      quart.q === getQuarter(new Date()) &&
                      quart.y === new Date().getFullYear()
                  })}
                >
                  Release Q{quart.q} {quart.y}
                </span>
              )
            })}
        </div>
      </div>

      <button
        onClick={() => handleNext()}
        className={styles.rightMoveBtn}
        disabled={!rightBtnEnabled}
      >
        <div className={styles.iconContainer}>
          <Icon icon={faChevronRight} />
        </div>
      </button>
    </div>
  )
}

export default TimelineBar
