import { AccountInfo } from '@azure/msal-browser'
import { Message } from '@intility/bifrost-react'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CardType, QuarterType, TagType } from '~/types/types'
import dragElement from '../../utils/dragElement'
import Card from '../Card/Card'
import CardModal from '../modals/Card/CardModal'
import DeleteCardModal from '../modals/DeleteCard/DeleteCardModal'
import UpdateCardModal from '../modals/UpdateCard/UpdateCardModal'
import './projectCardsContainer.css'

type ProjectCardsContainerProps = {
  admin: boolean
  cards: CardType[]
  columnWidth: number
  filteredCards: CardType[]
  getQuarter: (date: Date) => number
  newCard: CardType | undefined
  quarters: QuarterType[]
  serviceCardRef: React.RefObject<HTMLDivElement>
  setActiveIndex: React.Dispatch<React.SetStateAction<number | undefined>>
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
  tags: TagType[]
  todayQuart: QuarterType | undefined
  user: AccountInfo | null
}

const ProjectCardsContainer = ({
  admin,
  cards,
  columnWidth,
  filteredCards,
  getQuarter,
  newCard,
  quarters,
  serviceCardRef,
  setActiveIndex,
  setNewCard,
  tags,
  todayQuart,
  user
}: ProjectCardsContainerProps) => {
  const [dragging, setDragging] = useState(false)
  const [earlierCards, setEarlierCards] = useState<CardType[]>()

  useEffect(() => {
    const ecards = filteredCards.filter(c => c.year < 2018)
    setEarlierCards(ecards)
  }, [filteredCards])

  if (serviceCardRef && serviceCardRef.current) {
    const elmnt = serviceCardRef.current
    dragElement(elmnt, setDragging, setActiveIndex, columnWidth)
  }

  const { cardId } = useParams()
  const [selectedCard, setSelectedCard] = useState<CardType | undefined>(
    cards.find(card => card.id === cardId)
  )
  const [openEdit, setOpenEdit] = useState(false)
  const [cardToUpdate, setCardToUpdate] = useState<CardType | undefined>()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [cardToDelete, setCardToDelete] = useState<CardType | undefined>()

  const cardObject = (card: CardType) => (
    <Card
      key={card.id ? card.id : card.options.title}
      card={card}
      setSelectedCard={setSelectedCard}
      dragging={dragging}
      isNew={card?.id === newCard?.id}
      quarters={quarters}
      serviceCardRef={serviceCardRef}
      setNewCard={setNewCard}
      todayQuart={todayQuart}
    />
  )

  // OPENS THE "CONFIRM DELETE" MODAL
  const handleDelete = (card: CardType) => {
    setSelectedCard(undefined)
    setOpenEdit(false)
    setOpenConfirm(true)
    setCardToDelete(card)
  }

  // OPENS THE "EDIT PROJECT" MODAL
  const handleEdit = (card: CardType) => {
    setSelectedCard(undefined)
    setOpenEdit(true)
    setCardToUpdate(card)
  }

  return (
    <>
      <div className='serviceCardWrapper' ref={serviceCardRef}>
        <div id='serviceCardContainer'>
          {filteredCards && filteredCards.length === 0 && (
            <Message className='noResultMessage' state='neutral'>
              <i>No results</i>
            </Message>
          )}

          {/* Release Earlier column */}
          {earlierCards && earlierCards.length > 0 && (
            <div className='cardColumn' id={'earlier'}>
              {earlierCards.map(card => cardObject(card))}
            </div>
          )}

          {quarters
            .filter(c => c.y >= 2018)
            .map(q => {
              const quarterCards = filteredCards.filter(
                x => x.quart === q.q && x.year === q.y
              )

              if (!quarterCards.length && filteredCards.length !== cards.length)
                return null

              return (
                <div
                  className={classNames('cardColumn', {
                    activeQuarter:
                      q.q === getQuarter(new Date()) &&
                      q.y === new Date().getFullYear()
                  })}
                  key={q.id ? q.id : q.q.toString() + q.y.toString()}
                >
                  {quarterCards.map(card => cardObject(card))}
                </div>
              )
            })}
        </div>
      </div>

      {selectedCard && (
        <CardModal
          key={selectedCard.id}
          admin={admin}
          card={selectedCard}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          openInfo={!!selectedCard}
          setOpenInfo={setSelectedCard}
        />
      )}

      {openConfirm && cardToDelete && (
        <DeleteCardModal
          card={cardToDelete}
          cards={cards}
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
        />
      )}

      {openEdit && cardToUpdate && (
        <UpdateCardModal
          card={cardToUpdate}
          cards={cards}
          handleDelete={handleDelete}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          quarters={quarters}
          user={user}
          tags={tags}
          setNewCard={setNewCard}
        />
      )}
    </>
  )
}

export default ProjectCardsContainer
