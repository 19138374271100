import { mutate } from 'swr'

import { CardOptionsType, CardType } from '~/types/types'
import { authorizedFetch } from '../../../auth/fetch'
import { config } from '../../../config'
import handleCardProgress from '../../../utils/handleCardProgress'

const addCard = async (
  cards: CardType[],
  options: CardOptionsType,
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
) => {
  // Quarter and year
  const q = Math.ceil((options.endDate.getMonth() + 1) * 0.33)
  const y = options.endDate.getFullYear()

  const { progress, status } = handleCardProgress(options)

  // TODO: CHECK IF EMPTY ID IS OKAY
  const newCard: CardType = {
    id: '',
    quart: q,
    year: y,
    options: { ...options, status: status, progress: progress }
  }

  try {
    mutate(config.cards, [...cards, newCard], false)
    setNewCard(newCard)

    const res = await authorizedFetch(config.cards, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newCard)
    })
    if (!res.ok) {
      mutate(config.cards)
      throw new Error('Bad response: ' + res.status)
    }
  } catch (e) {
    console.log(e)
  }
}

export default addCard
