import useSWR from 'swr'

import { config } from '~/config'
import { CardType } from '~/types/types'

// CardItems
const useCardItems = () => {
  return useSWR<CardType[]>(config.cards, {
    revalidateOnFocus: false
  })
}

export default useCardItems
