import {
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication
} from '@azure/msal-react'
import { PropsWithChildren, useEffect } from 'react'

import instance, { loginHint } from './instance'
import LoginPage from './LoginPage'

export const loginRequest = {
  scopes: ['.default'],
  loginHint: loginHint
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#ssosilent-example
const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  )

  // If the error was due to the user being required to perform an interactive login
  const shouldTryInteractiveLogin =
    error instanceof InteractionRequiredAuthError && loginHint

  useEffect(() => {
    if (shouldTryInteractiveLogin) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [login, shouldTryInteractiveLogin])

  if (!shouldTryInteractiveLogin && error) {
    return <LoginPage />
  }

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
}

const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={instance}>
      <Template>{children}</Template>
    </MsalProvider>
  )
}

export default Authentication
