import { CardType, FilterType } from '~/types/types'

const handleFilterSelection = (
  cards: CardType[],
  filters: FilterType,
  pricings: string[],
  setFilteredCards: React.Dispatch<React.SetStateAction<CardType[]>>
) => {
  const filteredTags = (card: CardType) =>
    !filters.tags.length ||
    (card.options.tags &&
      filters.tags.some(y => card.options.tags?.includes(y)))

  const filteredPricingIncludes = (card: CardType) =>
    filters.pricing.includes(pricings[0])
      ? card.options.includedService
      : !card.options.includedService

  const filteredPricing = (card: CardType) =>
    !filters.pricing.length ||
    filters.pricing.length === 2 ||
    filteredPricingIncludes(card)

  const filteredStatuses = (card: CardType) =>
    !filters.statuses.length || filters.statuses.includes(card.options.status)

  const filteredYearEarlier = (card: CardType) =>
    filters?.year?.value === 'Earlier'
      ? card.year < 2018
      : card.year === filters?.year?.value

  const filteredYear = (card: CardType) =>
    !filters.year || filteredYearEarlier(card)

  const filteredSearchValue = (card: CardType) =>
    card.options.title.toLowerCase().includes(filters.search.toLowerCase()) ||
    (card.options.desc &&
      card.options.desc.toLowerCase().includes(filters.search.toLowerCase())) ||
    (card.options.shortDesc &&
      card.options.shortDesc
        .toLowerCase()
        .includes(filters.search.toLowerCase()))

  setFilteredCards(
    cards &&
      cards.filter(
        card =>
          // Apply filter for tags if any selected
          filteredTags(card) &&
          // Apply filter for selectedPricing if any selected or both selected
          filteredPricing(card) &&
          // Apply filter for selectedStatus if any selected
          filteredStatuses(card) &&
          // Apply filter by year if any selected
          filteredYear(card) &&
          // Apply filter if search field has value
          filteredSearchValue(card)
      )
  )
}

export default handleFilterSelection
