import useSWR from 'swr'

import { config } from '~/config'
import { TagType } from '~/types/types'

// CardItems
const useTagItems = () => {
  return useSWR<TagType[]>(config.tags, {
    revalidateOnFocus: false
  })
}

export default useTagItems
