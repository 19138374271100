import { AccountInfo } from '@azure/msal-browser'
import {
  Button,
  Checkbox,
  Grid,
  Inline,
  Input,
  Label,
  Modal,
  TextArea
} from '@intility/bifrost-react'
import DatePicker from '@intility/bifrost-react-datepicker'
import Select from '@intility/bifrost-react-select'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import useDisplayMode from '~/hooks/useDisplayMode'
import { CardType, QuarterType, TagType } from '~/types/types'
import { resetWindowScroll } from '../../../utils/resetWindowScroll'
import '../AddCard/AddCardModal.css'
import addQuarter from '../AddCard/addQuarter'
import updateCard from './updateCard'

type UpdateCardModalProps = {
  card: CardType
  cards: CardType[]
  handleDelete: (card: CardType) => void
  openEdit: boolean
  quarters: QuarterType[]
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>
  tags: TagType[]
  user: AccountInfo | null
}

const UpdateCardModal = ({
  card,
  cards,
  handleDelete,
  openEdit,
  quarters,
  setNewCard,
  setOpenEdit,
  tags,
  user
}: UpdateCardModalProps) => {
  const [hasChanged, setHasChanged] = useState(false)
  const [options, setOptions] = useState({
    ...card.options,
    startDate: new Date(card.options.startDate),
    endDate: new Date(card.options.endDate)
  })

  const { displayMode } = useDisplayMode()
  const [validatedInput, setValidatedInput] = useState(true)
  const [validatedDate, setValidatedDate] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const missingValue = 'Missing value'

  // VALIDATING DATE
  useEffect(() => {
    if (!options.startDate || !options.endDate) return
    setValidatedDate(options.endDate >= options.startDate)
  }, [options.startDate, options.endDate])

  // CREATING TAGOPTIONS FOR SELECT COMPONENT
  const tagOptions = useMemo(() => {
    if (tags) {
      return tags
        .flat()
        .map(x => ({
          label: x.tag,
          value: x.tag
        }))
        .sort()
    }
  }, [tags])

  // VALIDATING EMAIL FORMAT
  function validateEmail(mail: string) {
    const mailFormat = /^\w+(.\w+)*@\w+(.\w+)*(\.\w{2,3})+$/
    if (mail.match(mailFormat)) {
      setValidatedInput(true)
    } else {
      setValidatedInput(false)
    }
  }

  // CLOSING MODAL
  const handleModalClose = () => {
    resetWindowScroll()
    setOpenEdit(false)
    setSubmitted(false)
  }

  // HANDLE INPUT BASED ON FIELD CHANGED
  const handleInput = (type: string, value: string) => {
    setHasChanged(true)
    switch (type) {
      case 'Title':
        setOptions({ ...options, title: value })
        break
      case 'Email':
        setOptions({ ...options, email: value })
        break
      case 'ShortDesc':
        setOptions({ ...options, shortDesc: value })
        break
      case 'Desc':
        setOptions({ ...options, desc: value })
        break
      default:
    }
  }

  // SUBMITTING IF ALL REQUIRED FIELDS HAS VALUE
  const handleSubmit = () => {
    setSubmitted(true)
    if (
      !options.title ||
      !options.tags ||
      !options.startDate ||
      !options.endDate ||
      !options.email ||
      !options.shortDesc ||
      !validatedDate ||
      !validatedInput
    ) {
      return
    }

    if (hasChanged) {
      // Quarter and year
      const q = Math.ceil((options.endDate.getMonth() + 1) * 0.33)
      const y = options.endDate.getFullYear()
      // If quarter does not exist, add quarters
      if (!quarters.find(x => x.q === q && x.y === y)) {
        addQuarter(quarters, q, y)
      }

      // Update card
      updateCard(card, cards, options, user, setNewCard)
    }
    handleModalClose()
  }

  return (
    <Modal
      className={classNames('modal', 'editProject', 'bf-content', displayMode)}
      isOpen={openEdit}
      onRequestClose={() => handleModalClose()}
      noCloseOnOverlayClick
      header='Edit project'
    >
      <Grid gap={16} className='contentGrid'>
        <Input
          label='Title'
          className='projectTitle'
          {...(submitted &&
            !options.title && { state: 'alert', feedback: missingValue })}
          onChange={e => handleInput('Title', e.target.value)}
          defaultValue={options.title}
        />

        <div className='overrideStatusContainer'>
          <Label>Override status</Label>
          <Checkbox
            className='checkboxStatus'
            label='Project launched'
            checked={options.override ? true : false}
            onChange={() => {
              setOptions({ ...options, override: !options.override })
              setHasChanged(true)
            }}
          />
        </div>

        <div className='pricingModelContainer'>
          <Label>Pricing model</Label>
          <Checkbox
            className='checkboxStatus'
            label='Included'
            checked={options.includedService ? true : false}
            onChange={() => {
              setOptions({
                ...options,
                includedService: !options.includedService
              })
              setHasChanged(true)
            }}
          />
        </div>

        <Select
          name='Tag'
          className='selectTags'
          label='Tags'
          options={tagOptions}
          onChange={e => {
            setHasChanged(true)
            setOptions(old => ({
              ...old,
              tags: e.length > 0 ? e.map(t => t.value).join(',') : ''
            }))
          }}
          {...(submitted &&
            !options.tags && {
              state: 'alert',
              feedback: missingValue
            })}
          value={
            options?.tags && options.tags?.length > 0
              ? options.tags?.split(',').map(t => ({ label: t, value: t }))
              : []
          }
          isMulti
          isClearable={false}
        />

        <DatePicker
          className='startDate'
          label='Start date'
          selected={options.startDate}
          onChange={d => {
            if (!d) return
            const formatted = new Date(d.setHours(12))
            setOptions({ ...options, startDate: formatted })
            setHasChanged(true)
          }}
          {...(submitted &&
            !options.startDate.toString() && { state: 'alert' })}
          {...(!submitted &&
            !validatedDate && {
              state: 'warning',
              feedback: 'Must be before end date'
            })}
          {...(submitted &&
            !validatedDate && {
              state: 'alert',
              feedback: 'Must be before end date'
            })}
        />

        <DatePicker
          className='endDate'
          label='End date'
          selected={options.endDate}
          onChange={d => {
            if (!d) return
            const formatted = new Date(d.setHours(12))
            setOptions({ ...options, endDate: formatted })
            setHasChanged(true)
          }}
          {...(submitted && !options.endDate.toString() && { state: 'alert' })}
          {...(!submitted &&
            !validatedDate && {
              state: 'warning',
              feedback: 'Must be after start date'
            })}
          {...(submitted &&
            !validatedDate && {
              state: 'alert',
              feedback: 'Must be after start date'
            })}
        />

        <Input
          className='contactInfo'
          defaultValue={options.email}
          name='Email'
          id='contactInfo'
          label='Contact e-mail'
          {...(!validatedInput &&
            options.email && {
              state: 'alert',
              feedback: 'Invalid email address'
            })}
          {...(submitted &&
            !options.email && { state: 'alert', feedback: missingValue })}
          onChange={e => handleInput('Email', e.target.value)}
          onBlur={e => validateEmail(e.target.value)}
          placeholder='Ex. department@intility.no'
        />

        <Input
          className='shortDescInput'
          name='ShortDesc'
          id='shortDescInput'
          label='Brief description'
          {...(submitted &&
            !options.shortDesc && {
              state: 'alert',
              feedback: missingValue
            })}
          onChange={e => handleInput('ShortDesc', e.target.value)}
          defaultValue={options.shortDesc}
        />

        <TextArea
          className='descInput'
          name='Desc'
          label='Additional description'
          optional
          onChange={e => handleInput('Desc', e.target.value)}
          defaultValue={options.desc}
        />

        <Inline className='modalFooter'>
          <Button
            className='deleteBtn desktop'
            onClick={() => handleDelete(card)}
            variant='outline'
            state='alert'
          >
            Delete
          </Button>
          <Inline.Separator />
          <Button
            className='cancelBtn desktop'
            type='button'
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            className='saveBtn'
            onClick={() => handleSubmit()}
            variant='filled'
          >
            Save
          </Button>
        </Inline>
      </Grid>
    </Modal>
  )
}

export default UpdateCardModal
