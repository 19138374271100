import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Checkbox, Nav, Theme, useApplyTheme } from '@intility/bifrost-react'
import { IntilityAppsSwr } from '@intility/platform-toolkit-msal/swr'
import '@intility/platform-toolkit/css/IntilityApps.css'
import useLocalStorageState from 'use-local-storage-state'

import MyIntilityLogo from '~/assets/MyIntility64.png'
import { logout } from '../../auth/instance'
import ProfilePicture from '../ProfilePicture'
import styles from './NavHeader.module.css'

const NavHeader = () => {
  // persist theme state in local storage.
  // you might want to use a cookie or a database instead?
  const [theme, setTheme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'dark'
  })
  // keep document theme in sync with state
  useApplyTheme(theme)

  return (
    <>
      <IntilityAppsSwr activeApp='roadmap' />
      <Nav.Group name={<ProfilePicture />}>
        <a href='https://my.intility.com'>
          <Nav.Item hideCircle>
            <img
              src={MyIntilityLogo}
              alt='My Intility'
              className={styles.myIntilityLogo}
            />
            My Intility
          </Nav.Item>
        </a>
        <hr />
        <section>
          <Nav.Header>Color theme</Nav.Header>
          <Checkbox
            type='radio'
            label='Dark'
            name='color-theme'
            checked={theme === 'dark'}
            onChange={() => setTheme('dark')}
          />
          <Checkbox
            type='radio'
            label='Light'
            name='color-theme'
            checked={theme === 'light'}
            onChange={() => setTheme('light')}
          />
          <Checkbox
            type='radio'
            label='System'
            name='color-theme'
            checked={theme === 'system'}
            onChange={() => setTheme('system')}
          />
        </section>
        <hr />
        <button onClick={() => logout()}>
          <Nav.Item icon={faSignOutAlt} hideCircle>
            Log out
          </Nav.Item>
        </button>
      </Nav.Group>
    </>
  )
}

export default NavHeader
