import { useBreakpoint, useNav } from '@intility/bifrost-react'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { CardType, FilterType, TagType } from '~/types/types'
import { resetWindowScroll } from '../../utils/resetWindowScroll'
import FilterPricingModel from './FilterPricingModel'
import FilterYearMenu from './components/filterYearMenu'
import SearchBar from './components/searchBar'
import './filterMenu.css'
import FilterStatusMenu from './filterStatusMenu'
import FilterTagsMenu from './filterTagsMenu'
import handleCountUpdate from './utils/handleCountUpdate'
import handleFilterSelection from './utils/handleFilterSelection'

type FilterMenuProps = {
  admin: boolean
  cards: CardType[]
  setFilteredCards: React.Dispatch<React.SetStateAction<CardType[]>>
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  tags: TagType[]
  filters: FilterType
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>
  mobile?: boolean
}

function FilterMenu({
  admin,
  cards,
  setFilteredCards,
  setIsFetching,
  tags,
  filters,
  setFilters,
  mobile = false
}: FilterMenuProps) {
  // States
  const [allTags, setAllTags] = useState<string[]>([])
  const statusTitle = 'Status'
  const statuses = ['In development', 'Launched', 'Planned']
  const tagsTitle = 'Tags'
  const pricings = ['Included service', 'Optional service']

  // Breakpoints
  const toSmall = useBreakpoint(null, 'small')
  const fromMedium = useBreakpoint('medium')
  const { mobileOpen, setMobileOpen } = useNav()

  // Reset scroll when closing mobile menu
  useEffect(() => {
    if (!mobileOpen) {
      resetWindowScroll()
    }
  }, [mobileOpen])

  // Converting tags to single array
  useEffect(() => {
    if (!tags) return
    const t: string[] = []
    tags.forEach(item => t.push(item.tag))
    setAllTags(t)
  }, [tags])

  useEffect(() => {
    if (!fromMedium) return
    setMobileOpen(false)
  }, [fromMedium, setMobileOpen])

  // SETTING FILTERED CARDS BASED ON FILTERS AND SEARCH
  useEffect(() => {
    // No filters selected
    if (
      filters &&
      !filters.tags.length &&
      !filters.statuses.length &&
      !filters.pricing.length &&
      !filters.search.length &&
      !filters.year
    ) {
      setFilteredCards(cards)
      setFilters({
        ...filters,
        showAllTags: false,
        showAllStatus: false,
        showAllPricings: false
      })
    }
    // Tags, statuses or year selected
    else {
      handleFilterSelection(cards, filters, pricings, setFilteredCards)
    }
    handleCountUpdate(cards, filters, pricings, setFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.tags,
    filters.statuses,
    filters.pricing,
    filters.search,
    filters.year,
    cards,
    setFilteredCards
  ])

  // SETS THE SELECTED TAGS WHEN CLICKED
  const toggleTags = (tag: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFilters({ ...filters, tags: [...filters.tags, tag] })
    } else {
      setFilters({
        ...filters,
        tags: filters.tags.filter(x => x !== tag)
      })
    }
  }

  // SETS THE SELECTED STATUSES WHEN CLICKED
  const toggleStatus = (
    status: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setFilters({ ...filters, statuses: [...filters.statuses, status] })
    } else {
      setFilters({
        ...filters,
        statuses: filters.statuses.filter(x => x !== status)
      })
    }
  }

  // SETS THE SELECTED PRICINGS WHEN CLICKED
  const togglePricings = (
    pricing: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setFilters({ ...filters, pricing: [...filters.pricing, pricing] })
    } else {
      setFilters({
        ...filters,
        pricing: filters.pricing.filter(x => x !== pricing)
      })
    }
  }

  // SETS OR REMOVES SHOW ALL
  const setShowAll = (type: string) => {
    if (type === tagsTitle) {
      setFilters({
        ...filters,
        tags: filters.tags.length === allTags.length ? [] : allTags
      })
    } else if (type === statusTitle) {
      setFilters({
        ...filters,
        statuses: filters.statuses.length === statuses.length ? [] : statuses
      })
    } else if (type === 'Pricing model') {
      setFilters({
        ...filters,
        pricing: filters.pricing.length === pricings.length ? [] : pricings
      })
    }
  }

  const handleResetFilters = () => {
    const emptyFilter = {
      tags: [],
      statuses: [],
      pricing: [],
      year: null,
      search: '',
      showAllTags: false,
      showAllStatus: false,
      showAllPricings: false,
      count: cards,
      countYear: [],
      countStatus: [],
      countTags: [],
      countPricing: []
    }
    setFilters(emptyFilter)
  }

  const toSmallBp = toSmall ? 'to-small' : 'to-medium'
  const breakpoint = mobileOpen ? toSmallBp : 'from-medium'

  return (
    <div
      className={classNames(
        'filterMenusWrapper',
        'bf-scrollbar-small',
        'bfc-base-3-bg',
        breakpoint,
        {
          'to-medium': mobile,
          'from-medium': !mobile
        }
      )}
    >
      <SearchBar filters={filters} setFilters={setFilters} />

      <FilterYearMenu cards={cards} filters={filters} setFilters={setFilters} />

      <FilterTagsMenu
        tags={tags}
        admin={admin}
        cards={cards}
        toggleTags={toggleTags}
        setIsFetching={setIsFetching}
        setShowAll={setShowAll}
        filters={filters}
        handleResetFilters={handleResetFilters}
      />

      <FilterStatusMenu
        cards={cards}
        statuses={statuses}
        setShowAll={setShowAll}
        toggleStatus={toggleStatus}
        filters={filters}
      />

      <FilterPricingModel
        cards={cards}
        pricings={pricings}
        setShowAll={setShowAll}
        togglePricings={togglePricings}
        filters={filters}
      />
    </div>
  )
}

export default FilterMenu
