import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPen, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Button, Checkbox, Icon, Input, Label } from '@intility/bifrost-react'
import classNames from 'classnames'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { mutate } from 'swr'

import type { CardType, FilterType, TagType } from '~/types/types'
import { authorizedFetch } from '../../auth/fetch'
import { config } from '../../config'
import './filterMenu.css'
import TagCheckbox from './tagCheckbox'

type FilterTagsMenuProps = {
  admin: boolean
  cards: CardType[]
  filters: FilterType
  handleResetFilters: () => void
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  setShowAll: (title: string) => void
  tags: TagType[]
  toggleTags: (tag: string, e: React.ChangeEvent<HTMLInputElement>) => void
}

// FILTER TAGS MENU COMPONENT
const FilterTagsMenu = ({
  admin,
  cards,
  filters,
  handleResetFilters,
  setIsFetching,
  setShowAll,
  tags,
  toggleTags
}: FilterTagsMenuProps) => {
  const title = 'Tags'
  const [editMode, setEditMode] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [searchParams] = useSearchParams()

  // CAPITALIZING NEW TAG
  const capitalize = (s: string) => {
    return s
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const handleTagInput = (value: string) => {
    setNewTag(value)
  }

  // ADDING NEW TAG
  const addNewTag = async () => {
    if (!newTag.length) {
      return
    }
    const t = capitalize(newTag)
    const tag = {
      tag: t
    }

    setIsFetching(true)
    try {
      const res = await authorizedFetch(config.tags, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tag)
      })
      if (!res.ok) {
        throw new Error('Could not add tag')
      }
    } catch (e) {
      console.log(e)
    } finally {
      mutate(config.tags)
      setIsFetching(false)
      setNewTag('')
    }
  }

  // CLOSING EDIT MODE WHEN ADDING NEW TAG
  const handleAddNewTag = () => {
    if (newTag.length) {
      setEditMode(false)
      addNewTag()
    }
  }

  const [showMore, setShowMore] = useState(searchParams.size > 0 ? true : false)

  return (
    <>
      <div className='filterTagsMenu'>
        <div className={classNames('filterMenuHeader', { isAdmin: admin })}>
          <Label className='filterTitle'>{title}</Label>

          {admin && (
            <button
              className={classNames('editModeBtn', 'bf-title-link')}
              onClick={() => setEditMode(x => !x)}
            >
              <Icon icon={editMode ? faTimes : faPen} />
            </button>
          )}

          {searchParams.size > 0 && (
            <button
              className={classNames('bf-title-link', 'resetFiltersBtn')}
              onClick={() => handleResetFilters()}
            >
              <i>Clear filters</i>
            </button>
          )}
        </div>

        <div className='filterMenuContent'>
          <Checkbox
            label={
              'Show all (' +
              (!filters.count ? cards.length : filters?.countTags?.length) +
              ')'
            }
            checked={filters.tags.length === tags.length}
            onChange={() => setShowAll(title)}
          />
          {tags.slice(0, editMode || showMore ? tags.length : 8).map(tag => (
            <TagCheckbox
              key={tag.id ? tag.id : tag.tag}
              admin={admin}
              cards={cards}
              editMode={editMode}
              filterCount={filters.count}
              filterCountTags={filters.countTags}
              setEditMode={setEditMode}
              selectedTags={filters.tags}
              setIsFetching={setIsFetching}
              toggleTags={toggleTags}
              tag={tag}
              tags={tags}
            />
          ))}
          {editMode && (
            <div className='addNewTagContainer bfc-base-3-bg'>
              <Input
                label='Add tag'
                hideLabel
                placeholder='Add new tag'
                value={newTag}
                onChange={e => handleTagInput(e.target.value)}
              />
              <Button
                className='addTagBtn'
                icon={faPlus}
                onClick={() => handleAddNewTag()}
                variant='filled'
                small
              />
            </div>
          )}
          {!editMode && (
            <button
              onClick={() => setShowMore(show => !show)}
              className={classNames('bf-title-link', 'showMoreBtn')}
            >
              {showMore ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default FilterTagsMenu
