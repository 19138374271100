import { faExclamationCircle, faRedo } from '@fortawesome/pro-regular-svg-icons'
import { Button, Icon, Nav, Tooltip } from '@intility/bifrost-react'

import styles from './ErrorPage.module.css'
import NavHeader from './Header/NavHeader'

const ErrorPage = ({ error, title }: { error?: string; title?: string }) => {
  return (
    <Nav className='bf-content' logo='Roadmap' top={<NavHeader />}>
      <div className={styles.container}>
        <div className={styles.errorImage} />
        <div className={styles.content}>
          <h2 className={styles.title}>
            {title ?? 'Could not load data'}
            <Tooltip content={error} state='neutral'>
              <Icon
                icon={faExclamationCircle}
                marginLeft
                fixedWidth
                className='bfc-base-2'
              />
            </Tooltip>
          </h2>
          <span className={styles.info}>
            You can try again, or contact Intility Support.{' '}
          </span>
          <div className={styles.errorButtonsContainer}>
            <Button
              icon={faRedo}
              onClick={() => window.location.reload()}
              variant='filled'
            >
              Try again
            </Button>
            <a href='https://my.intility.com/support'>
              <Button>Go to support</Button>
            </a>
          </div>
        </div>
      </div>
    </Nav>
  )
}

export default ErrorPage
