import { AccountInfo } from '@azure/msal-browser'
import { mutate } from 'swr'

import { CardOptionsType, CardType } from '~/types/types'
import { authorizedFetch } from '../../../auth/fetch'
import { config } from '../../../config'
import handleCardProgress from '../../../utils/handleCardProgress'

// UPDATING CARD
const updateCard = async (
  card: CardType,
  cards: CardType[],
  options: CardOptionsType,
  user: AccountInfo | null,
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
) => {
  // Quarter and year
  const q = Math.ceil((options.endDate.getMonth() + 1) * 0.33)
  const y = options.endDate.getFullYear()

  const { progress, status } = handleCardProgress(options)

  const newCard = {
    id: card.id,
    quart: q,
    year: y,
    options: {
      ...options,
      lastEditedBy: user?.username,
      status: status,
      progress: progress
    }
  }

  try {
    const newCards = cards.map(c => (c === card ? newCard : c))
    mutate(config.cards, newCards, false)
    setNewCard(newCard)

    const res = await authorizedFetch(config.cards + `/${card.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newCard)
    })
    if (!res.ok) {
      mutate(config.cards)
      throw new Error('Could not update card.')
    }
  } catch (e) {
    console.log(e)
  }
}

export default updateCard
