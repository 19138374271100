import { useAccount } from '@azure/msal-react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  FloatingArea,
  Icon,
  Spinner,
  Tooltip,
  useBreakpoint
} from '@intility/bifrost-react'
import '@intility/bifrost-react-datepicker/datepicker.css'
import '@intility/bifrost-react/dist/bifrost-app.css'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import useCardItems from '~/api/useCardItems'
import useQuarterItems from '~/api/useQuarterItems'
import useTagItems from '~/api/useTagItems'
import { CardType, QuarterType, TagType } from '~/types/types'
import './App.css'
import { authorizedFetch } from './auth/fetch'
import ErrorPage from './components/ErrorPage'
import FilterMenu from './components/FilterMenu/filterMenu'
import LoadingPage from './components/LoadingPage'
import Navigation from './components/Navigation'
import AddCardModal from './components/modals/AddCard/AddCardModal'
import ProjectCardsContainer from './components/projectsContainer/projectCardsContainer'
import TimelineBar from './components/timelinebar/timelinebar'
import { config } from './config'
import useDisplayMode from './hooks/useDisplayMode'
import { FilterType } from './types/types'
import { getQuartIndex } from './utils/getQuartIndex'
import isAdmin from './utils/isAdmin'
import progressUpdate from './utils/progressUpdate'
import sortCards from './utils/sortCards'

function App() {
  const user = useAccount()

  const [searchParams, setSearchParams] = useSearchParams()
  const { displayMode } = useDisplayMode()

  // Data states
  const [cards, setCards] = useState<CardType[]>()
  const [filteredCards, setFilteredCards] = useState<CardType[]>([])
  const [quarters, setQuarters] = useState<QuarterType[]>()
  const [tags, setTags] = useState<TagType[]>()
  const [activeIndex, setActiveIndex] = useState<number>()

  // Loading state when fetching data
  const [isFetching, setIsFetching] = useState<boolean>(false)

  // Temporary state for a card when addind a new or updating one
  const [newCard, setNewCard] = useState<CardType>()

  // Role spesification to tell if user has admin rights
  const [admin, setAdmin] = useState<boolean>(false)

  // Filtermenu states
  const [filters, setFilters] = useState<FilterType>({
    tags: searchParams.getAll('tag') ?? [],
    statuses: searchParams.getAll('status') ?? [],
    pricing: searchParams.getAll('price') ?? [],
    year: searchParams.get('year')
      ? {
          value: parseInt(searchParams.get('year') ?? '')
        }
      : null,
    search: searchParams.get('search') ?? '',
    count: cards,
    countYear: [],
    countStatus: [],
    countTags: [],
    countPricing: []
  } as FilterType)

  useEffect(() => {
    setSearchParams({
      ...(filters.search.length > 0 && { search: filters.search }),
      ...(filters.year && { year: filters.year.value.toString() }),
      ...(filters.tags.length > 0 &&
        filters.tags.length !== tags?.length && { tag: filters.tags }),
      ...(filters.statuses.length > 0 &&
        filters.statuses.length !== 3 && { status: filters.statuses }),
      ...(filters.pricing.length > 0 &&
        filters.pricing.length !== 2 && { price: filters.pricing })
    })
  }, [filters, setSearchParams, tags])

  // Refs
  const serviceCardRef = useRef<HTMLDivElement>(null)
  const quarterTitleRef = useRef<HTMLDivElement>(null)

  const [openAddProject, setOpenAddProject] = useState<boolean>(false)

  // Sets todays quarter
  const [todayQuart, setTodayQuart] = useState<QuarterType>()

  // Columnwidth states
  const toLarge = useBreakpoint(null, 'large')
  const columnWidth = toLarge ? 330 : 360

  // Fetchers
  const { data: cardData, error: cardError } = useCardItems()
  const { data: quarterData, error: quarterError } = useQuarterItems()
  const { data: tagsData, error: tagsError } = useTagItems()

  useEffect(() => {
    setCards(cardData)
  }, [cardData])

  useEffect(() => {
    setQuarters(quarterData)
  }, [quarterData])

  useEffect(() => {
    setTags(tagsData)
  }, [tagsData])

  // Setting errors
  const error = cardError || quarterError || tagsError

  // Checking roles if logged in user has admin rights
  useEffect(() => {
    user && isAdmin(setAdmin)
  }, [user])

  // CALCULATES QUARTER BASED ON DATE (yyyy-mm-dd)
  function getQuarter(date: Date) {
    const month = date.getMonth() + 1
    return Math.ceil(month * 0.33)
  }

  const addFirstQuarter = async () => {
    const quart = { q: 1, y: 2018 }
    try {
      const quarterResponse = await authorizedFetch(config.quarters, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(quart)
      })
      if (!quarterResponse.ok) {
        throw new Error('Could not post first quarter')
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Setting todayQuart
  useEffect(() => {
    if (!quarters) return
    if (quarters.length === 0) {
      addFirstQuarter()
      return
    }
    const quart = { q: getQuarter(new Date()), y: new Date().getFullYear() }
    const todayQuartExist = quarters.find(
      x => x.q === quart.q && x.y === quart.y
    )
    if (todayQuartExist) {
      setTodayQuart(todayQuartExist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quarters])

  // SETTING ACTIVEINDEX TO TODAYQUART
  useEffect(() => {
    if (!quarters || !cards) return
    let todayQuartIndex = quarters.findIndex(
      x => x.q === getQuarter(new Date()) && x.y === new Date().getFullYear()
    )

    if (cards.filter(c => c.year < 2018).length > 0) {
      todayQuartIndex += 1
    }
    if (!activeIndex) {
      setActiveIndex(todayQuartIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, quarters])

  // UPDATE PROGRESS WHEN CARDS CHANGE
  useEffect(() => {
    if (!cards) return
    progressUpdate(cards, setCards)
  }, [cards])

  // Sort cards by progress, then by date
  useEffect(() => {
    if (!filteredCards) return
    sortCards(filteredCards, setFilteredCards)
  }, [filteredCards])

  // AFTER USING FILTERS, SETS PAGE BACK TO INITIAL PLACEMENT
  useEffect(() => {
    if (!serviceCardRef.current) return
    if (!cards || !filteredCards || !activeIndex) return
    if (cards.length !== filteredCards.length) return
    const cardElementWrapper = serviceCardRef.current
    cardElementWrapper.scrollTo({
      left: activeIndex * columnWidth,
      behavior: 'smooth'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCards])

  // SCROLL TO COLUMN OF NEWLY ADDED/UPDATED CARD
  useEffect(() => {
    if (!serviceCardRef.current) return
    const cardElementWrapper = serviceCardRef.current
    if (cardElementWrapper && quarters && newCard) {
      const newCardIndex = getQuartIndex(quarters, newCard)
      const columnsAmount = Math.floor((window.innerWidth - 240) / columnWidth)
      const currentIndex = Math.floor(
        cardElementWrapper.scrollLeft / columnWidth
      )
      // SCROLL TO QUARTER OF THE NEW CARD
      if (
        newCardIndex < currentIndex ||
        newCardIndex > currentIndex + columnsAmount - 1
      ) {
        setActiveIndex(newCardIndex + 1)
      }
    }
  }, [columnWidth, newCard, quarters])

  // EVENLISTENER FOR SCROLLING TIMELINEBAR WITH CARDELEMENTS
  if (serviceCardRef.current && quarterTitleRef.current) {
    const cardElementWrapper = serviceCardRef.current
    const quarterTitleElementWrapper = quarterTitleRef.current
    // SETTING EQUAL SCROLLVALUE ON BOTH WRAPPERS
    const selectScroll = () => {
      quarterTitleElementWrapper.scrollLeft = cardElementWrapper.scrollLeft
    }
    cardElementWrapper.addEventListener('scroll', selectScroll)
  }

  const [leftBtnEnabled, setLeftBtnEnabled] = useState(true)
  const [rightBtnEnabled, setRightBtnEnabled] = useState(true)
  const [todayBtnEnabled, setTodayBtnEnabled] = useState(true)

  // SET ARROWS DISABLED OR NOT
  const updateArrows = useCallback(() => {
    if (!serviceCardRef.current || !quarters) return
    const cardElementWrapper = serviceCardRef.current
    // Left scroll
    const leftScroll = cardElementWrapper.scrollLeft
    // Right scroll
    const rightScroll = Math.floor(
      cardElementWrapper.scrollLeft + cardElementWrapper.clientWidth
    )
    // TodayScroll
    let todayIndex = quarters.findIndex(x => x === todayQuart)
    // if "earlier" column exists
    if (cards && cards.filter(c => c.year < 2018).length > 0) {
      todayIndex += 1
    }
    const todayScroll = todayIndex * columnWidth

    if (leftScroll === 0) {
      setLeftBtnEnabled(false)
    } else {
      setLeftBtnEnabled(true)
    }
    if (cardElementWrapper.scrollWidth === rightScroll) {
      setRightBtnEnabled(false)
    } else {
      setRightBtnEnabled(true)
    }
    if (leftScroll === todayScroll) {
      setTodayBtnEnabled(false)
    } else {
      setTodayBtnEnabled(true)
    }
  }, [cards, columnWidth, quarters, todayQuart])

  useEffect(() => {
    updateArrows()
  }, [filteredCards, updateArrows])

  // Listen to window scroll value
  useEffect(() => {
    if (!serviceCardRef.current) return
    const cardElementWrapper = serviceCardRef.current
    cardElementWrapper.addEventListener('scroll', updateArrows)
    return () => {
      cardElementWrapper.removeEventListener('scroll', updateArrows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceCardRef.current])

  // TODAYBUTTON: SCROLLS TO CURRENT QUARTER
  const handleToday = () => {
    if (!serviceCardRef.current) return
    const cardElementWrapper = serviceCardRef.current
    let todayIndex = quarters?.findIndex(x => x === todayQuart)
    if (!todayIndex) return

    // if "earlier" column exists
    if (cards && cards.filter(c => c.year < 2018).length > 0) {
      todayIndex += 1
    }
    cardElementWrapper.scrollTo({
      top: 0,
      left: todayIndex * columnWidth,
      behavior: 'smooth'
    })
    setActiveIndex(todayIndex)
  }

  // PREV BUTTON SCROLLS THE PAGE LEFT
  const handlePrev = () => {
    if (!serviceCardRef.current) return
    const cardElementWrapper = serviceCardRef.current
    const value = Math.floor(cardElementWrapper.scrollLeft)
    if (activeIndex && value === activeIndex * columnWidth) {
      cardElementWrapper.scrollTo({
        left: (activeIndex - 1) * columnWidth,
        behavior: 'smooth'
      })
      setActiveIndex(activeIndex - 1)
    } else {
      cardElementWrapper.scrollTo({
        left: Math.floor(value / columnWidth) * columnWidth,
        behavior: 'smooth'
      })
      setActiveIndex(Math.floor(value / columnWidth))
    }
  }

  // NEXT BUTTON SCROLLS THE PAGE RIGHT
  const handleNext = () => {
    if (!serviceCardRef.current) return
    const cardElementWrapper = serviceCardRef.current
    const value = Math.ceil(cardElementWrapper.scrollLeft)
    if (cardElementWrapper.scrollLeft === 0) {
      cardElementWrapper.scrollTo({
        left: columnWidth,
        behavior: 'smooth'
      })
      setActiveIndex(1)
    } else if (activeIndex && value === activeIndex * columnWidth) {
      cardElementWrapper.scrollTo({
        left: (activeIndex + 1) * columnWidth,
        behavior: 'smooth'
      })
      setActiveIndex(activeIndex + 1)
    } else {
      cardElementWrapper.scrollTo({
        left: Math.ceil(value / columnWidth) * columnWidth,
        behavior: 'smooth'
      })
      setActiveIndex(Math.ceil(value / columnWidth))
    }
  }

  // If error
  if (error) {
    let err = error
    if (typeof error === 'object') {
      err = error.message
    }
    return <ErrorPage error={err} />
  }

  // While loading page content
  if (
    !cards ||
    !cards.length ||
    !quarters ||
    !quarters.length ||
    !tags ||
    !filters
  ) {
    return <LoadingPage />
  }

  return (
    <Navigation
      mobile={
        <>
          <FilterMenu
            admin={admin}
            cards={cards}
            setFilteredCards={setFilteredCards}
            setIsFetching={setIsFetching}
            tags={tags}
            filters={filters}
            setFilters={setFilters}
            mobile
          />
        </>
      }
    >
      <>
        {isFetching && <Spinner overlay />}

        {admin && (
          <AddCardModal
            cards={cards}
            quarters={quarters}
            setNewCard={setNewCard}
            open={openAddProject}
            setOpen={setOpenAddProject}
            tags={tags}
            user={user}
          />
        )}

        <FloatingArea>
          <div className='floatingAreaContent'>
            {admin && (
              <Tooltip
                content='Add project'
                placement='left'
                state='neutral'
                className='addProjectTooltip'
              >
                <Button
                  className='addCardBtn'
                  onClick={() => setOpenAddProject(true)}
                  pill
                  noPadding
                  variant='filled'
                >
                  <Icon icon={faPlus} />
                </Button>
              </Tooltip>
            )}

            {todayBtnEnabled && (
              <Button
                onClick={() => handleToday()}
                pill
                className='bf-shadow-bottom'
                variant='filled'
              >
                Go to today
              </Button>
            )}
          </div>
        </FloatingArea>

        <div className={classNames('appContainer', displayMode)}>
          <FilterMenu
            filters={filters}
            setFilters={setFilters}
            admin={admin}
            cards={cards}
            tags={tags}
            setFilteredCards={setFilteredCards}
            setIsFetching={setIsFetching}
          />

          <TimelineBar
            handlePrev={handlePrev}
            handleNext={handleNext}
            getQuarter={getQuarter}
            quarters={quarters}
            filteredCards={filteredCards}
            cardsLength={cards.length}
            leftBtnEnabled={leftBtnEnabled}
            rightBtnEnabled={rightBtnEnabled}
            quarterTitleRef={quarterTitleRef}
          />

          <ProjectCardsContainer
            admin={admin}
            cards={cards}
            columnWidth={columnWidth}
            filteredCards={filteredCards}
            getQuarter={getQuarter}
            newCard={newCard}
            quarters={quarters}
            serviceCardRef={serviceCardRef}
            setActiveIndex={setActiveIndex}
            setNewCard={setNewCard}
            tags={tags}
            todayQuart={todayQuart}
            user={user}
          />
        </div>
      </>
    </Navigation>
  )
}

export default App
