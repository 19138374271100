import { CardOptionsType } from '~/types/types'

const handleCardProgress = (options: CardOptionsType) => {
  const today = new Date().getTime()
  const start = options.startDate.getTime()
  const end = options.endDate.getTime()

  let progress = 100
  let status = 'Launched'

  if (!options.override) {
    if (start < today && today < end) {
      if (Math.round(((today - start) / (end - start)) * 100) > 90) {
        progress = 90
      } else {
        progress = Math.round(((today - start) / (end - start)) * 100)
      }
      status = 'In development'
    } else if (start < end && end < today) {
      progress = 90
      status = 'In development'
    } else {
      progress = 0
      status = 'Planned'
    }
  }

  return { progress, status }
}

export default handleCardProgress
