import { Theme, useApplyTheme } from '@intility/bifrost-react'
import useLocalStorageState from 'use-local-storage-state'

import RoadmapLogo from '~/assets/Roadmap.svg'
import styles from './LoadingPage.module.css'

const LoadingPage = () => {
  const [theme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'dark'
  })
  // keep document theme in sync with state
  useApplyTheme(theme)

  return (
    <div className={styles.loadingPageContainer}>
      <div className={styles.loadingPageGrid}>
        <img className={styles.loadingLogo} src={RoadmapLogo} alt='' />
      </div>
    </div>
  )
}

export default LoadingPage
