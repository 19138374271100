import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Input } from '@intility/bifrost-react'
import React, { useEffect, useState } from 'react'

import { FilterType } from '~/types/types'
import { useDebouncedState } from '../../../hooks/useDebounce'

type SearchBarProps = {
  filters: FilterType
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>
}

const SearchBar = ({ filters, setFilters }: SearchBarProps) => {
  const [value, setValue] = useState(filters.search ?? '')
  const debouncedValue = useDebouncedState(value)

  // Remove search value when filters resets
  useEffect(() => {
    if (!filters.search) {
      setValue('')
    }
  }, [filters])

  // When value is removed, reset filter
  useEffect(() => {
    if (!debouncedValue || !debouncedValue.length) {
      setFilters(prev => ({ ...prev, search: '' }))
    } else {
      setFilters(prev => ({ ...prev, search: debouncedValue }))
    }
  }, [debouncedValue, setFilters])

  return (
    <div className='rm-search-container'>
      <Input
        label='Search for project'
        placeholder='Search'
        value={value}
        onChange={e => setValue(e.target.value)}
        icon={faSearch}
        rightIcon
        clearable
      />
    </div>
  )
}

export default SearchBar
