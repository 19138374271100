import instance from '../auth/instance'
import { config } from '../config'

// IF USER HAS WRITER RIGHTS AND IS INTILITY EMPLOYEE, SET AS ADMIN
const isAdmin = (setAdmin: React.Dispatch<React.SetStateAction<boolean>>) => {
  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  }

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
  instance
    .acquireTokenSilent({
      scopes: [config.SCOPE_URI!],
      redirectUri: 'http://localhost:3000/blank.html'
    })
    .then(token => {
      if (token.tenantId !== '9b5ff18e-53c0-45a2-8bc2-9c0c8f60b2c6') return
      const tokenData = parseJwt(token.accessToken)
      if (!tokenData.roles) return
      if (tokenData.roles.includes('Writer')) {
        setAdmin(true)
      } else {
        setAdmin(false)
      }
    })
}

export default isAdmin
