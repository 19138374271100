import { faCalendarAlt, faCheck } from '@fortawesome/pro-regular-svg-icons'
import {
  Badge,
  Button,
  Grid,
  Icon,
  Inline,
  Modal,
  ProgressBar,
  useBreakpoint
} from '@intility/bifrost-react'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './cardModal.css'

import useDisplayMode from '~/hooks/useDisplayMode'
import { CardOptionsType, CardType } from '~/types/types'

type CardModalProps = {
  admin: boolean
  handleDelete: (card: CardType) => void
  handleEdit: (card: CardType) => void
  card: CardType
  openInfo: boolean
  setOpenInfo: React.Dispatch<React.SetStateAction<CardType | undefined>>
}

const CardModal = ({
  admin,
  handleDelete,
  handleEdit,
  card,
  openInfo,
  setOpenInfo
}: CardModalProps) => {
  const navigate = useNavigate()

  const options: CardOptionsType = card.options
  const { displayMode } = useDisplayMode()
  const [hasLink, setHasLink] = useState(false)
  const [descUrl, setDescUrl] = useState('')
  const [descStart, setDescStart] = useState('')
  const [descEnd, setDescEnd] = useState('')
  const [cardTags, setCardTags] = useState<string[]>([])

  const toSmall = useBreakpoint(null, 'small')

  useEffect(() => {
    const desc = options.desc ? options.desc : ''
    if (desc && desc.includes('http')) {
      const url = desc.match(/\bhttps?:\/\/\S+/gi)?.[0]
      if (!url) return
      setDescUrl(url)
      setDescStart(desc.substring(0, desc.indexOf(url)))
      setDescEnd(desc.substring(desc.indexOf(url) + url.length))
      setHasLink(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  // Getting tags from string
  useEffect(() => {
    if (!options.tags) return
    const t = options.tags.split(',')
    t.sort()
    setCardTags(t)
  }, [options])

  const getDate = (date: Date) => {
    return new Date(date).toLocaleDateString('nb-NO', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
  }

  return (
    <>
      <Modal
        className={classNames(
          'modal',
          'bf-content',
          'bf-scrollbar',
          displayMode
        )}
        isOpen={openInfo}
        onRequestClose={() => {
          setOpenInfo(undefined)
          navigate('/')
        }}
        noPadding
      >
        <div className='cardinfo-container'>
          <div className='ns-modal-sidebar'>
            <div className='dateContainer'>
              <span>
                <Icon icon={faCalendarAlt} />
                <b> Start date </b>
              </span>
              <div className='date'>{getDate(options.startDate)}</div>
            </div>
            <div className='dateContainer'>
              <span>
                <Icon icon={faCalendarAlt} />
                <b> End date </b>
              </span>
              <div className='date'>{getDate(options.endDate)}</div>
            </div>

            <div className='cardInfoTagsContainer'>
              {cardTags &&
                cardTags.map(tag => (
                  <Badge key={tag} state='neutral'>
                    {tag}
                  </Badge>
                ))}
            </div>
          </div>

          <Grid gap={16} className='cardInfoContent'>
            <div>
              <h2 className='cardInfoTitle'>{options.title}</h2>
              <div className='pricing-model'>
                {options.includedService && (
                  <Icon className='circleCheck' icon={faCheck} />
                )}
                {options.includedService ? (
                  <span> Included for all customers </span>
                ) : (
                  <i> Optional service </i>
                )}
              </div>
            </div>

            <span className='shortDesc'> {options.shortDesc} </span>

            {options.desc && (
              <>
                <hr className='seperator'></hr>
                {hasLink ? (
                  <span className='infoModalDesc'>
                    {descStart}
                    <a className='bf-link' href={descUrl}>
                      {descUrl}
                    </a>
                    {descEnd}
                  </span>
                ) : (
                  <span className='infoModalDesc'> {options.desc} </span>
                )}
              </>
            )}

            <div>
              <ProgressBar
                disabled={options.progress === 0}
                value={options.progress}
                size='small'
              />
              <div className='cardStatus'> {options.status} </div>
            </div>

            <div className='cardInfoContact'>
              <span>
                <b> Contact: </b>
                <a className='bf-link' href={'mailto:' + options.email}>
                  {options.email}
                </a>
              </span>
            </div>

            {/* Below 600px screen width */}
            {toSmall && (
              <div className='ns-modal-no-sidebar'>
                <hr className='seperator'></hr>
                <div className='datesContainer'>
                  <div className='dateContainer'>
                    <span>
                      <Icon icon={faCalendarAlt} />
                      <b> Start date </b>
                    </span>
                    <div className='date'>{getDate(options.startDate)}</div>
                  </div>
                  <div className='dateContainer'>
                    <span>
                      <Icon icon={faCalendarAlt} />
                      <b> End date </b>
                    </span>
                    <div className='date'>{getDate(options.endDate)}</div>
                  </div>
                </div>

                <div className='cardInfoTagsContainer'>
                  {cardTags &&
                    cardTags.map(tag => (
                      <Badge key={tag} state='neutral'>
                        {tag}
                      </Badge>
                    ))}
                </div>
              </div>
            )}

            {admin && (
              <Inline style={{ marginTop: '12px' }}>
                <Button
                  className='deleteBtn desktop'
                  onClick={() => {
                    navigate('/')
                    handleDelete(card)
                  }}
                  variant='outline'
                  state='alert'
                >
                  Delete
                </Button>
                <Inline.Separator />
                <Button
                  className='cancelBtn desktop'
                  onClick={() => {
                    navigate('/')
                    setOpenInfo(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className='editBtn'
                  variant='filled'
                  onClick={() => {
                    navigate('/')
                    handleEdit(card)
                  }}
                >
                  Edit
                </Button>
              </Inline>
            )}
          </Grid>
        </div>
      </Modal>
    </>
  )
}

export default CardModal
