import useSWR from 'swr'

import { config } from '~/config'
import { QuarterType } from '~/types/types'

// CardItems
const useQuarterItems = () => {
  return useSWR<QuarterType[]>(config.quarters, {
    revalidateOnFocus: false
  })
}

export default useQuarterItems
