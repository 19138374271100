import { CardType } from '~/types/types'

// Update progressbar on all cards
const progressUpdate = (
  cards: CardType[],
  setCards: React.Dispatch<React.SetStateAction<CardType[] | undefined>>
) => {
  const today = new Date().getTime()

  cards.forEach(card => {
    const options = card.options
    const sd = new Date(options.startDate).getTime()
    const ed = new Date(options.endDate).getTime()
    if (options.override === false) {
      if (sd < today && today < ed) {
        if (Math.round(((today - sd) / (ed - sd)) * 100) > 90) {
          options.progress = 90
        } else {
          options.progress = Math.round(((today - sd) / (ed - sd)) * 100)
        }
        options.status = 'In development'
      } else if (sd < ed && ed < today) {
        options.progress = 90
        options.status = 'In development'
      } else {
        options.progress = 0
        options.status = 'Planned'
      }
    } else {
      options.progress = 100
      options.status = 'Launched'
    }
  })
  setCards(cards)
}

export default progressUpdate
