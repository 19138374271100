import { wrapCreateBrowserRouter } from '@sentry/react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route
} from 'react-router-dom'

import App from './App'
import ErrorPage from './components/ErrorPage'

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage error='Something went wrong' />}
      element={<Outlet />}
    >
      <Route path='/' element={<App />} />
      <Route path='/:cardId' element={<App />} />
    </Route>
  )
)

export { router }
