// Hook to detect if the app is running in a browser or as a PWA
// https://stackoverflow.com/questions/17989777/detect-if-ios-is-using-webapp/40932301#40932301

const useDisplayMode = () => {
  let displayMode = 'browser'

  const isInWebAppiOS = 'standalone' in navigator && navigator.standalone
  const mqStandAlone = '(display-mode: standalone)'
  if (isInWebAppiOS || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone'
  }

  return { displayMode }
}

export default useDisplayMode
