import { Bifrost, enUS } from '@intility/bifrost-react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'

import Authentication from './auth/Authentication'
import { authorizedFetch } from './auth/fetch'
import './index.css'
import { router } from './router'
import { initializeSentry } from './utils/initializeSentry'

// Set default theme to dark if no theme preference is set
if (!localStorage?.getItem('bfTheme')) {
  localStorage?.setItem('bfTheme', '"dark"')
}

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)

// Default SWR fetcher
const swrConfigValue = {
  fetcher: (url: string) =>
    authorizedFetch(url).then(response => {
      if (!response.ok) {
        console.error(response)
        const error = {
          message: 'An error occurred while fetching the data.',
          info: response,
          status: response.status,
          statusText: response.statusText
        }
        throw error
      }
      return response.json()
    })
}

root.render(
  <StrictMode>
    <Authentication>
      <SWRConfig value={swrConfigValue}>
        <Bifrost locale={enUS}>
          <RouterProvider router={router} />
        </Bifrost>
      </SWRConfig>
    </Authentication>
  </StrictMode>
)

initializeSentry()
