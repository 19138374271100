import { CardType } from '~/types/types'

const sortByDate = (a: Date, b: Date) => {
  return a > b ? 1 : b > a ? -1 : 0
}

const sortCards = (
  cards: CardType[],
  setFilteredCards: React.Dispatch<React.SetStateAction<CardType[]>>
) => {
  // Sort on progress, then on date
  cards.sort(function (a, b) {
    const n = b.options.progress - a.options.progress
    if (n !== 0) {
      return n
    }
    return sortByDate(a.options.endDate, b.options.endDate)
  })
  setFilteredCards(cards)
}

export default sortCards
