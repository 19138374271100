import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Badge, Grid, Icon, ProgressBar } from '@intility/bifrost-react'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardType, QuarterType } from '~/types/types'
import styles from './Card.module.css'

type CardProps = {
  card: CardType
  setSelectedCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
  dragging: boolean
  isNew: boolean
  quarters: QuarterType[]
  serviceCardRef: React.RefObject<HTMLDivElement>
  setNewCard: React.Dispatch<React.SetStateAction<CardType | undefined>>
  todayQuart?: QuarterType
}

const Card = ({
  card,
  setSelectedCard,
  dragging,
  isNew,
  serviceCardRef,
  setNewCard,
  todayQuart
}: CardProps) => {
  const options = card.options
  const [showBorder, setShowBorder] = useState(isNew)
  const [cardTags, setCardTags] = useState<string[]>([])
  const cardRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  // MAKES CARDS KEYBOARD ACCESSABLE
  const keyboardClick = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const code = e.code
    // 13: space, 32: return
    if (code === 'Space' || code === 'Enter') {
      e.preventDefault()
      handleOnClickCard()
    }
  }

  // SCROLLING VERTICALLY TO THE NEW CARD
  useEffect(() => {
    if (isNew) {
      setTimeout(() => {
        if (!serviceCardRef.current || !cardRef.current) return
        serviceCardRef.current.scrollTo({
          top: cardRef.current.offsetTop - 150,
          behavior: 'smooth'
        })
      }, 1000)

      setTimeout(() => {
        setShowBorder(false)
        setNewCard(undefined)
      }, 5000)
    }
  }, [isNew, setNewCard, cardRef, serviceCardRef])

  useEffect(() => {
    if (!options.tags) return
    const t = options.tags.split(',')
    t.sort()
    setCardTags(t)
  }, [options])

  const handleOnClickCard = () => {
    if (dragging) return
    setSelectedCard(card)
    navigate(`/${card.id}`)
  }

  const activeQuarter =
    card.quart === todayQuart?.q && card.year === todayQuart?.y

  return (
    <div
      className={classNames(styles.cardWrapper, 'bf-content', {
        [styles.activeQuarter]: activeQuarter
      })}
    >
      <div className={styles.cardOverlay}>
        <div
          className={classNames(styles.cardContainer, {
            [styles.cardContainerBorder]: showBorder
          })}
          ref={cardRef}
          id={card.id}
          onMouseUp={() => handleOnClickCard()}
          tabIndex={0}
          role='button'
          onKeyDown={e => keyboardClick(e)}
        >
          <div className={styles.cardTitleContainer}>
            <span className={styles.cardTitle}>{options.title}</span>
            {options.includedService && (
              <div className={styles.circleCheck}>
                <Icon icon={faCheck} />
              </div>
            )}
          </div>

          <div className={styles.cardLineCircle}>
            <span className={styles.cardDot}></span>
          </div>

          <Grid className={styles.cardContent} gap={16}>
            <p className={styles.cardDesc}> {options.shortDesc} </p>
            <div>
              {cardTags.length &&
                cardTags.map(tag => (
                  <Badge
                    key={tag}
                    state={activeQuarter ? 'default' : 'neutral'}
                    className={styles.cardTag}
                  >
                    {tag}
                  </Badge>
                ))}
            </div>

            <Grid gap={0}>
              <ProgressBar
                disabled={options.progress === 0}
                className={styles.cardProgress}
                value={options.progress}
                size='small'
              />

              <p className={styles.cardStatus}> {options.status} </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Card
