import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Checkbox, Icon } from '@intility/bifrost-react'
import { useState } from 'react'

import type { CardType, TagType } from '~/types/types'
import DeleteTagModal from '../modals/DeleteTagModal/DeleteTagModal'
import './filterMenu.css'

type TagCheckboxProps = {
  admin: boolean
  cards: CardType[]
  editMode: boolean
  filterCount?: CardType[]
  filterCountTags?: CardType[]
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  selectedTags: string[]
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  toggleTags: (tag: string, e: React.ChangeEvent<HTMLInputElement>) => void
  tag: TagType
  tags: TagType[]
}

// COMPONENT FOR EACH TAGS CHECKBOX
const TagCheckbox = ({
  admin,
  cards,
  editMode,
  filterCount,
  filterCountTags,
  setEditMode,
  selectedTags,
  setIsFetching,
  toggleTags,
  tag,
  tags
}: TagCheckboxProps) => {
  const [openDeleteTag, setOpenDeleteTag] = useState(false)
  const countArray = !filterCount ? cards : filterCountTags

  // Length of array with cards that has the selected tag
  const cardsWithTag = countArray?.filter(c =>
    c.options.tags
      ? c.options.tags.includes(tag.tag)
      : c.options.tag === tag.tag
  ).length

  return (
    <>
      {openDeleteTag && (
        <DeleteTagModal
          openDeleteTag={openDeleteTag}
          setOpenDeleteTag={setOpenDeleteTag}
          tag={tag}
          tags={tags}
          setIsFetching={setIsFetching}
          cards={cards}
          setEditMode={setEditMode}
        />
      )}

      <div className='checkboxWithDeleteBtn'>
        <Checkbox
          className='filter-checkbox'
          label={tag.tag + ' (' + cardsWithTag + ')'}
          checked={selectedTags && selectedTags.indexOf(tag.tag) > -1}
          onChange={e => toggleTags(tag.tag, e)}
          disabled={!cardsWithTag}
        />
        {admin && editMode && (
          <Icon
            onClick={() => setOpenDeleteTag(true)}
            className='deleteTagBtn'
            icon={faTimes}
          />
        )}
      </div>
    </>
  )
}

export default TagCheckbox
