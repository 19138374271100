import { useEffect, useRef, useState } from 'react'

const debounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  delay = 300
) => {
  let timeout: NodeJS.Timeout
  return (...args: T) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(...args), delay)
  }
}

const useDebounce = <T>(
  input: T,
  setValue: (input: T) => void,
  delay = 300,
  setInitialValue = false
) => {
  // assume value is the same as input first render
  // to avoid calling setValue with value
  // as setValue might contain side-effects
  const initialValue = useRef<T | object>(input)
  useEffect(() => {
    if (input !== initialValue.current || setInitialValue) {
      const timeout = setTimeout(() => setValue(input), delay)
      // set initialValue to something unique
      // to allow setting the initial value after it's been changed
      initialValue.current = {}
      return () => clearTimeout(timeout)
    }
  }, [input, setValue, delay, setInitialValue])
}

const useDebouncedState = <T>(input: T, delay = 300) => {
  const [value, setValue] = useState(input)
  useDebounce(input, setValue, delay)
  return value
}

export { debounce, useDebouncedState }
export default useDebounce
