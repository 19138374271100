import { Nav } from '@intility/bifrost-react'
import { ComponentProps, ReactNode } from 'react'

import RoadmapLogo from '~/assets/Roadmap_192px.png'
import NavHeader from './Header/NavHeader'

interface NavigationProps extends ComponentProps<typeof Nav> {
  children: ReactNode
}

const Navigation = ({ children, ...props }: NavigationProps) => {
  return (
    <Nav
      className='bf-scrollbar bf-content'
      logo={
        <a href='/' className='bf-neutral-link nav-logo-link'>
          <Nav.Logo logo={RoadmapLogo}>Intility Roadmap</Nav.Logo>
        </a>
      }
      top={<NavHeader />}
      {...props}
    >
      {children}
    </Nav>
  )
}

export default Navigation
