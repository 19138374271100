export const config = {
  MULTITENANT_CLIENT_ID: 'fb735f1c-079a-4431-9c2e-e195f86818c1',
  MULTITENANT_AUTHORITY: 'https://login.microsoftonline.com/common',

  INTILITY_CLIENT_ID: '5ba43394-b119-4f7d-beee-4512c94e566e',
  INTILITY_AUTHORITY:
    'https://login.microsoftonline.com/9b5ff18e-53c0-45a2-8bc2-9c0c8f60b2c6',

  USE_INTILITY_CLIENT_ID: import.meta.env.VITE_USE_INTILITY_CLIENT_ID,

  SERVER_HOST: import.meta.env.VITE_SERVER_HOST,
  SCOPE_URI: import.meta.env.VITE_SCOPE_URI,

  cards: import.meta.env.VITE_SERVER_HOST + '/api/CardItems',
  quarters: import.meta.env.VITE_SERVER_HOST + '/api/QuarterItems',
  tags: import.meta.env.VITE_SERVER_HOST + '/api/TagItems'
}
