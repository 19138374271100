import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { Icon, Skeleton } from '@intility/bifrost-react'
import { useEffect, useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'

import { authorizedFetch } from '../auth/fetch'
import styles from './ProfilePicture.module.css'

const blobFetcher = (url: string) =>
  authorizedFetch(url).then(r => {
    if (!r.ok) throw new Error('Could not fetch profile picture')
    return r.blob()
  })

const ProfilePicture = () => {
  const { data, error, isValidating } = useSWRImmutable(
    'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
    blobFetcher
  )

  const imgUrl = useMemo(() => data && URL.createObjectURL(data), [data])

  useEffect(
    () => () => {
      imgUrl && URL.revokeObjectURL(imgUrl)
    },
    [imgUrl]
  )

  if (!data && isValidating) {
    return (
      <div className={styles.profilePhotoFallback}>
        <Skeleton.Rect width={30} height={30} />
      </div>
    )
  }

  if (!imgUrl || error)
    return (
      <div className={styles.profilePhotoFallback}>
        <Icon icon={faUser} />
      </div>
    )

  return <img alt='' className={styles.profilePhoto} src={imgUrl} />
}

export default ProfilePicture
